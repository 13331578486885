import { DataGrid, GridRenderCellParams, ukUA } from '@mui/x-data-grid';
import * as React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import useModal from '../../shared/hooks/useModal/useModal';
import { AdditionalDataModal } from '../../Bank/AvailableCodes/components/AdditionalDataModal';
import { useState } from 'react';
import Uzflag from '../../../icons/countryflags/uz.svg';
import Kgflag from '../../../icons/countryflags/kg.svg';
import Uaflag from '../../../icons/countryflags/ua.svg';
import Kzflag from '../../../icons/countryflags/kz.svg';
import Azflag from '../../../icons/countryflags/az.svg';
import Pkflag from '../../../icons/countryflags/pk.svg';
import Caflag from '../../../icons/countryflags/ca.svg';
import styles from '../DataTable/DataTable.module.scss';
import { style } from '../DataTable/style';

interface Props {
  data: any;
}

export const AvailableCodesTable: React.FC<Props> = ({ data }) => {
  const [pageSize, setPageSize] = React.useState(25);
  const { isShow, openModal, closeModal } = useModal();
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const renderCustomCell = (element: any) => {
    switch (element.countryCode) {
      case null:
        return <div>{''}</div>;
      case 'KG':
        return (
          <div className={styles.customCell}>
            <img src={Kgflag} alt="" />
            &nbsp;&nbsp;
            <span>{element.countryName}</span>
          </div>
        );
      case 'UZ':
        return (
          <div className={styles.customCell}>
            <img src={Uzflag} alt="" />
            &nbsp;&nbsp;
            <span>{element.countryName}</span>
          </div>
        );
      case 'UA':
        return (
          <div className={styles.customCell}>
            <img src={Uaflag} alt="" />
            &nbsp;&nbsp;
            <span>{element.countryName}</span>
          </div>
        );
      case 'KZ':
        return (
          <div className={styles.customCell}>
            <img src={Kzflag} alt="" />
            &nbsp;&nbsp;
            <span>{element.countryName}</span>
          </div>
        );
      case 'AZ':
        return (
          <div className={styles.customCell}>
            <img src={Azflag} alt="" />
            &nbsp;&nbsp;
            <span>{element.countryName}</span>
          </div>
        );
      case 'PK':
        return (
          <div className={styles.customCell}>
            <img src={Pkflag} alt="" />
            &nbsp;&nbsp;
            <span>{element.countryName}</span>
          </div>
        );
      case 'CA':
        return (
          <div className={styles.customCell}>
            <img src={Caflag} alt="" />
            &nbsp;&nbsp;
            <span>{element.countryName}</span>
          </div>
        );
      default:
        return <div>{''}</div>;
    }
  };

  return (
    <div style={{ height: 630, width: '100%', marginTop: '20px' }}>
      <DataGrid
        localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
        rows={data}
        headerHeight={40}
        rowHeight={36}
        columns={[
          {
            field: 'countryName',
            headerName: 'Країна',
            width: 200,
            renderCell: (params: GridRenderCellParams<Date>) => renderCustomCell(params.row),
          },
          { field: 'countryCode', headerName: 'Код', width: 80 },
          { field: 'EAN13', headerName: 'Код GTIN', width: 140 },
          { field: 'internalCode', headerName: 'Внутрішній код', width: 140 },
          {
            field: 'internalName',
            headerName: 'Внутрішня назва продукції',
            minWidth: 450,
            flex: 1,
          },
          {
            field: 'marketName',
            headerName: 'Експортна назва продукції',
            minWidth: 450,
            flex: 2,
          },
          {
            field: 'button',
            headerName: 'Меню',
            headerAlign: 'center',
            width: 60,
            align: 'center',
            renderCell: (params: GridRenderCellParams) => (
              <MenuIcon
                className={styles.icon}
                onClick={() => {
                  setSelectedRow({ productId: params.row.productId, countryCodeId: params.row.countryCodeId });
                  openModal();
                }}
              />
            ),
          },
        ]}
        getRowId={(row: any) => row.position}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        sx={style}
        componentsProps={{
          pagination: {
            SelectProps: {
              MenuProps: {
                sx: {
                  '& .MuiMenuItem-root': {
                    fontSize: 14,
                    bgcolor: '#ffffff',
                  },
                },
                PaperProps: {
                  sx: {
                    bgcolor: '#ffffff',
                  },
                },
              },
            },
          },
        }}
      />
      {isShow && <AdditionalDataModal isShown={isShow} closeModal={closeModal} params={selectedRow} />}
    </div>
  );
};
