import MenuIcon from '@mui/icons-material/Menu';
import { DataGrid, GridRenderCellParams, ukUA } from '@mui/x-data-grid';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import useModal from '../../shared/hooks/useModal/useModal';
import styles from '../DataTable/DataTable.module.scss';
import Running from '../../../icons/run_circle.svg';
import Finished from '../../../icons/check_circle.svg';
import Canceled from '../../../icons/cancel.svg';
import { PrintCodesInfoModal } from '../../OperationsAndTools/PrintCodes/components/PrintCodesInfoModal';
import { style } from '../DataTable/style';
import { Tooltip } from '@mui/material';

interface Props {
  data: any;
  filterPrintJobs: () => void;
}

export const PrintCodesTable: React.FC<Props> = ({ data, filterPrintJobs }) => {
  const { isShow, openModal, closeModal } = useModal();
  const [pageSize, setPageSize] = React.useState(25);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const renderCustomCell = (element: any) => {
    switch (element.jobState) {
      case null:
        return <div>{''}</div>;
      case 1:
        return (
          <div className={styles.customCell}>
            <Tooltip title="Виконується" placement="top">
              <img src={Running} alt="" />
            </Tooltip>
          </div>
        );
      case 2:
        return (
          <div className={styles.customCell}>
            <Tooltip title="Завершено" placement="top">
              <img src={Finished} alt="" />
            </Tooltip>
          </div>
        );
      case 3:
        return (
          <div className={styles.customCell}>
            <Tooltip title="Завершено з помилкою" placement="top">
              <img src={Canceled} alt="" />
            </Tooltip>
          </div>
        );
      default:
        return <div>{''}</div>;
    }
  };

  return (
    <div style={{ height: 580, width: '100%', marginTop: '20px' }}>
      <DataGrid
        localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
        rows={data}
        headerHeight={40}
        rowHeight={36}
        columns={[
          { field: 'number', headerName: 'Номер завдання', width: 140 },
          {
            field: 'startDate',
            headerName: 'Дата створення',
            width: 145,
            renderCell: (params: GridRenderCellParams<Date>) => (
              <span>{moment(params.row.startDate).format('DD.MM.YYYY HH:mm:ss')}</span>
            ),
          },
          {
            field: 'jobStateIcon',
            headerName: 'Статус',
            headerAlign: 'center',
            width: 70,
            align: 'center',
            renderCell: (params: GridRenderCellParams<Date>) => renderCustomCell(params.row),
          },
          { field: 'receiptId', headerName: 'Номер рецепту', width: 120 },
          { field: 'toPrintCount', headerName: 'До друку, шт', width: 120 },
          { field: 'countryCode', headerName: 'Країна', width: 80 },
          { field: 'gtin', headerName: 'Код GTIN', width: 130 },
          {
            field: 'internalName',
            headerName: 'Внутрішня назва продукції',
            minWidth: 330,
            flex: 0.3,
          },
          { field: 'series', headerName: 'Серія', width: 150 },
          { field: 'clientDescription', headerName: 'Виробнича лінія', width: 150 },
          { field: 'login', headerName: 'Користувач', minWidth: 80, flex: 0.7 },
          {
            field: 'button',
            headerName: 'Меню',
            headerAlign: 'center',
            width: 60,
            align: 'center',
            renderCell: (params: GridRenderCellParams<Date>) => (
              <MenuIcon
                className={styles.icon}
                onClick={() => {
                  setSelectedRow({ printJobId: params.row.id });
                  openModal();
                }}
              />
            ),
          },
        ]}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        sx={style}
        componentsProps={{
          pagination: {
            SelectProps: {
              MenuProps: {
                sx: {
                  '& .MuiMenuItem-root': {
                    fontSize: 14,
                    bgcolor: '#ffffff',
                  },
                },
                PaperProps: {
                  sx: {
                    bgcolor: '#ffffff',
                  },
                },
              },
            },
          },
        }}
      />
      <PrintCodesInfoModal
        isShown={isShow}
        params={selectedRow}
        closeModal={closeModal}
        filterPrintJobs={filterPrintJobs}
      />
    </div>
  );
};
