import { combineReducers } from 'redux';
import { BackFlowProcessesReducer } from './BackFlowProcesses/services/reducer';
import { ImportReportCodesReducer } from './ImportReport/services/reducer';
import { AggregationReportReducer } from './CodesReport/services/reducer';
import { SeriesReportReducer } from './SeriesReport/services/reducer';
import { AuditTrailReportReducer } from './AuditTrail/services/reducer';
import { LicensedDevicesReducer } from './LicensedDevices/services/reducer';
import { ShipmentReportReducer } from './Shipment/services/reducer';
import { WithdrawalReportReducer } from './Withdrawal/services/reducer';

export const eventsLogModuleReducer = combineReducers({
  ImportReportCodesReducer,
  AggregationReportReducer,
  ShipmentReportReducer,
  LicensedDevicesReducer,
  BackFlowProcessesReducer,
  SeriesReportReducer,
  AuditTrailReportReducer,
  WithdrawalReportReducer,
});

export type EventsLogModuleReducer = ReturnType<typeof eventsLogModuleReducer>;
