import DownloadIcon from '@mui/icons-material/Download';
import { DataGrid, GridRenderCellParams, ukUA } from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';
import moment from 'moment/moment';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { getExcelFileTableRequest } from '../../EventsLog/Shipment/services/actions';
import styles from '../DataTable/DataTable.module.scss';
import { style } from '../DataTable/style';

interface Props {
  data: any;
  select?: number;
}

export const ShippmentReportTable: React.FC<Props> = ({ data, select }) => {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = React.useState(25);

  const boxGrid = [
    { field: 'sscc', headerName: 'SSCC код ящика', width: 160 },
    { field: 'externalShippingDocumentNumber', headerName: 'Номер накладної', width: 160 },
    {
      field: 'shippingDate',
      headerName: 'Час сканування',
      width: 150,
      renderCell: (params: GridRenderCellParams<Date>) => (
        <span>{moment(params.row.shippingDate).format('DD-MM-YYYY HH:mm:ss')}</span>
      ),
    },
    { field: 'palletSscc', headerName: 'Агреговано в палету', width: 160 },
    { field: 'ean13', headerName: 'Код GTIN', width: 130 },
    { field: 'internalProductName', headerName: 'Внутрішня назва продукції', minWidth: 340, flex: 1 },
    { field: 'series', headerName: 'Серія', width: 140 },
    { field: 'countryCode', headerName: 'Країна', width: 80 },
    { field: 'unitsCount', headerName: 'Одиниць продукції, шт', minWidth: 140, flex: 2 },
    {
      field: 'button',
      headerName: 'Файли',
      width: 70,
      align: 'right',
      renderCell: (params: GridRenderCellParams<Date>) => (
        <Tooltip title="Скачати як звіт в Excel" placement="top">
          <a
            onClick={() =>
              dispatch(getExcelFileTableRequest({ SSCC: select === 2 ? params.row.sscc : params.row.palletSscc }))
            }
          >
            <DownloadIcon className={styles.icon} />
          </a>
        </Tooltip>
      ),
    },
  ];

  const palletGrid = [
    { field: 'palletSscc', headerName: 'SSCC код палети', width: 160 },
    { field: 'externalShippingDocumentNumber', headerName: 'Номер накладної', width: 160 },
    {
      field: 'shippingDate',
      headerName: 'Час сканування',
      width: 150,
      renderCell: (params: GridRenderCellParams<Date>) => (
        <span>{moment(params.row.shippingDate).format('DD-MM-YYYY HH:mm:ss')}</span>
      ),
    },
    { field: 'ean13', headerName: 'Код GTIN', width: 130 },
    { field: 'internalProductName', headerName: 'Внутрішня назва продукції', minWidth: 340, flex: 1 },
    { field: 'series', headerName: 'Серія', width: 140 },
    { field: 'countryCode', headerName: 'Країна', width: 80 },
    { field: 'unitsCount', headerName: 'Кількість ящиків, шт', minWidth: 140, flex: 2 },
    {
      field: 'button',
      headerName: 'Файли',
      width: 70,
      align: 'right',
      renderCell: (params: GridRenderCellParams<Date>) => (
        <a
          onClick={() =>
            dispatch(getExcelFileTableRequest({ SSCC: select === 2 ? params.row.Sscc : params.row.palletSscc }))
          }
        >
          <DownloadIcon className={styles.icon} />
        </a>
      ),
    },
  ];

  return (
    <div style={{ height: 580, width: '100%', marginTop: '20px' }}>
      <DataGrid
        localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
        rows={data}
        headerHeight={40}
        rowHeight={36}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        // @ts-expect-error
        columns={select === 2 ? boxGrid : palletGrid}
        getRowId={() => Math.random()}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        sx={style}
        componentsProps={{
          pagination: {
            SelectProps: {
              MenuProps: {
                sx: {
                  '& .MuiMenuItem-root': {
                    fontSize: 14,
                    bgcolor: '#ffffff',
                  },
                },
                PaperProps: {
                  sx: {
                    bgcolor: '#ffffff',
                  },
                },
              },
            },
          },
        }}
      />
    </div>
  );
};
