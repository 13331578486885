import { SendersInfoSagaWatcher } from './BackFlowProcesses/services/saga';
import { ImportReportSagaWatcher } from './ImportReport/services/saga';
import { AggregationReportSagaWatcher } from './CodesReport/services/saga';
import { SeriesReportSagaWatcher } from './SeriesReport/services/saga';
import { AuditTrailReportSagaWatcher } from './AuditTrail/services/saga';
import { LicensedDevicesSagaWatcher } from './LicensedDevices/services/saga';
import { ShipmentReportSagaWatcher } from './Shipment/services/saga';
import { WithdrawalReportSagaWatcher } from './Withdrawal/services/saga';

export const eventsLogModuleSaga = [
  LicensedDevicesSagaWatcher,
  ImportReportSagaWatcher,
  AggregationReportSagaWatcher,
  ShipmentReportSagaWatcher,
  SendersInfoSagaWatcher,
  SeriesReportSagaWatcher,
  AuditTrailReportSagaWatcher,
  WithdrawalReportSagaWatcher,
];
