import { AxiosResponse } from 'axios';
import { instance as axios } from 'api/axios';

export class Receipts {
  public static async getReceipts(body: any) {
    const url = '/Receipt/Get';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async deleteDeactivate(body: any) {
    const url = '/Receipt/Deactivate';
    const response: AxiosResponse = await axios.delete(url, { data: body });
    return response;
  }

  public static async getProductList() {
    const url = '/Product/List';
    const response: AxiosResponse = await axios.post(url);
    return response;
  }

  public static async postCreate(body: any) {
    const url = '/Receipt/Create';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }
}
