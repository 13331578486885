import MenuIcon from '@mui/icons-material/Menu';
import { DataGrid, GridRenderCellParams, ukUA } from '@mui/x-data-grid';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './ProductsList.module.scss';
import { style } from './style';

interface Props {
  data: any;
}

const ProductsListTable: React.FC<Props> = ({ data }) => {
  const [pageSize, setPageSize] = React.useState(25);

  return (
    <div style={{ height: 630, width: '100%', marginTop: '20px' }}>
      <DataGrid
        localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
        rows={data}
        headerHeight={40}
        rowHeight={36}
        columns={[
          { field: 'id', headerName: 'ID', width: 30 },
          { field: 'gtin', headerName: 'Код GTIN', width: 130 },
          { field: 'internalProductCode', headerName: 'Внутрішній код продукту', width: 190 },
          { field: 'internalName', headerName: 'Внутрішня назва продукції', minWidth: 450, flex: 1 },
          {
            field: 'button',
            headerName: 'Меню',
            headerAlign: 'center',
            width: 60,
            align: 'center',
            renderCell: (params: GridRenderCellParams<Date>) => (
              <NavLink to={`/product?${params.row.gtin}`}>
                <MenuIcon className={styles.icon} />
              </NavLink>
            ),
          },
        ]}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        sx={style}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        componentsProps={{
          pagination: {
            SelectProps: {
              MenuProps: {
                sx: {
                  '& .MuiMenuItem-root': {
                    fontSize: 14,
                    bgcolor: '#ffffff',
                  },
                },
                PaperProps: {
                  sx: {
                    bgcolor: '#ffffff',
                  },
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default ProductsListTable;
