import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { GET_SERIES_REPORT_REQUEST, GET_EXCEL_FILE_SERIES_REQUEST } from './constants';
import {
  getSeriesReportError,
  getSeriesReportRequest,
  getSeriesReportSuccess,
  getExcelFileSeriesError,
  getExcelFileSeriesRequest,
  getExcelFileSeriesSuccess,
} from './actions';
import { Reports } from '../../../../entities/Reports/service';

function* getSeriesReportSaga(action: ReturnType<typeof getSeriesReportRequest>) {
  try {
    const response: AxiosResponse = yield call(Reports.getSeriesReport, action.payload);
    const seriesReport = response.data.ResponseBody;
    yield put(getSeriesReportSuccess(seriesReport));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getSeriesReportError(error));
  }
}

function* getExcelFileSeriesRequestSaga(action: ReturnType<typeof getExcelFileSeriesRequest>) {
  try {
    yield call(Reports.getExcelSeriesReport, action.payload);
    yield put(getExcelFileSeriesSuccess());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getExcelFileSeriesError(error));
  }
}

export function* SeriesReportSagaWatcher() {
  yield takeLatest(GET_SERIES_REPORT_REQUEST, getSeriesReportSaga);
  yield takeLatest(GET_EXCEL_FILE_SERIES_REQUEST, getExcelFileSeriesRequestSaga);
}
