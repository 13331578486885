import MenuIcon from '@mui/icons-material/Menu';
import PhotoIcon from '@mui/icons-material/Photo';
import { DataGrid, GridRenderCellParams, ukUA } from '@mui/x-data-grid';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useModal from '../../shared/hooks/useModal/useModal';
import {
  clearPhotoBox,
  getBoxTemplatesByIdRequest,
  getPhotoBoxRequest,
} from '../../Templates/BoxTemplates/services/actions';
import { boxTemplateSelector } from '../../Templates/BoxTemplates/services/selector';
import { getPalletTemplatesByIdRequest, getPhotoPalletRequest } from '../../Templates/PalletTemplates/services/actions';
import { palletTemplateSelector } from '../../Templates/PalletTemplates/services/selector';
import {
  clearPhotoUnit,
  getPhotoUnitRequest,
  getUnitTemplatesByIdRequest,
} from '../../Templates/UnitTemplates/services/actions';
import { unitTemplateSelector } from '../../Templates/UnitTemplates/services/selector';
import styles from '../DataTable/DataTable.module.scss';
import EditTemplateModal from '../EditTemplateModal';
import ImageViewerModal from '../ImageViewerModal';
import { style } from '../DataTable/style';
import { getCountryListRequest } from '../../../entities/Countries/actions';

interface Props {
  data: any;
  level: number;
}

export const TemplatesTable: React.FC<Props> = ({ data, level }) => {
  const [pageSize, setPageSize] = React.useState(25);

  const { isShow, openModal, closeModal } = useModal();
  const { isShow: isShowViewer, openModal: openViewer, closeModal: closeViewer } = useModal();
  const dispatch = useDispatch();
  const { selectedBoxTemplate, photoBox } = useSelector(boxTemplateSelector);
  const { selectedPalletTemplates, photoPallet } = useSelector(palletTemplateSelector);
  const { selectedUnitTemplates, photoUnit } = useSelector(unitTemplateSelector);

  const onCloseModal = () => {
    dispatch(clearPhotoUnit());
    dispatch(clearPhotoBox());
    dispatch(clearPhotoUnit());
    closeViewer();
  };

  const onEditHandler = (params: any) => {
    if (level === 0) {
      dispatch(getUnitTemplatesByIdRequest({ labelTemplateId: params.row.id }));
    }
    if (level === 3) {
      dispatch(getPalletTemplatesByIdRequest({ labelTemplateId: params.row.id }));
    }
    if (level === 2) {
      dispatch(getBoxTemplatesByIdRequest({ labelTemplateId: params.row.id }));
    }
    dispatch(getCountryListRequest());
    openModal();
  };

  const onOpenViewer = (params: any) => {
    if (level === 0 && params?.row?.photos.length !== 0) {
      dispatch(getPhotoUnitRequest(params?.row?.photos[0]?.url));
    }
    if (level === 3 && params?.row?.photos.length !== 0) {
      dispatch(getPhotoPalletRequest(params?.row?.photos[0]?.url));
    }
    if (level === 2 && params?.row?.photos.length !== 0) {
      dispatch(getPhotoBoxRequest(params?.row?.photos[0]?.url));
    }
    openViewer();
  };

  return (
    <div style={{ height: 630, width: '100%', marginTop: '20px' }}>
      <DataGrid
        localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
        rows={data}
        headerHeight={40}
        rowHeight={36}
        columns={[
          { field: 'id', headerName: 'ID', width: 70 },
          {
            field: 'image',
            headerName: 'Фото',
            headerAlign: 'center',
            renderCell: (params: GridRenderCellParams<Date>) => (
              <PhotoIcon
                className={params.row.photos.length === 0 ? styles.noPhotoIcon : styles.photoIcon}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  params.row.photos.length === 0 ? undefined : onOpenViewer(params);
                }}
              />
            ),
            width: 70,
            align: 'center',
          },
          { field: 'templateName', headerName: 'Найменування шаблону', width: 300 },
          { field: 'countryCode', headerName: 'Країна', width: 80 },
          { field: 'lang', headerName: 'Мова коду принтера', width: 160 },
          { field: 'usedIn', headerName: 'Пов`язані об`єкти', minWidth: 450, flex: 0.9, align: 'left' },
          {
            field: 'button',
            headerName: 'Меню',
            headerAlign: 'center',
            width: 60,
            align: 'center',
            renderCell: (params: GridRenderCellParams<Date>) => (
              <MenuIcon className={styles.icon} onClick={() => onEditHandler(params)} />
            ),
          },
        ]}
        disableSelectionOnClick
        disableColumnMenu
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        sx={style}
        componentsProps={{
          pagination: {
            SelectProps: {
              MenuProps: {
                sx: {
                  '& .MuiMenuItem-root': {
                    fontSize: 14,
                    bgcolor: '#ffffff',
                  },
                },
                PaperProps: {
                  sx: {
                    bgcolor: '#ffffff',
                  },
                },
              },
            },
          },
        }}
      />
      {level === 2 ? (
        <EditTemplateModal isShown={isShow} level={level} data={selectedBoxTemplate} closeModal={closeModal} />
      ) : null}
      {level === 3 ? (
        <EditTemplateModal isShown={isShow} level={level} data={selectedPalletTemplates} closeModal={closeModal} />
      ) : null}
      {level === 0 ? (
        <EditTemplateModal isShown={isShow} level={level} data={selectedUnitTemplates} closeModal={closeModal} />
      ) : null}
      {level === 0 ? (
        <ImageViewerModal
          isShownViewer={isShowViewer}
          closeViewerModal={onCloseModal}
          img={photoUnit}
          extended={false}
        />
      ) : null}
      {level === 2 ? (
        <ImageViewerModal
          isShownViewer={isShowViewer}
          closeViewerModal={onCloseModal}
          img={photoBox}
          extended={false}
        />
      ) : null}
      {level === 3 ? (
        <ImageViewerModal
          isShownViewer={isShowViewer}
          closeViewerModal={onCloseModal}
          img={photoPallet}
          extended={false}
        />
      ) : null}
    </div>
  );
};
