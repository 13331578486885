import { AxiosResponse } from 'axios';
import { instance as axios } from 'api/axios';

export class Reports {
  public static async getImportReport(body: any) {
    const url = '/Report/Imports/ByDates';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async getSeriesReport(body: any) {
    const url = '/Report/Series';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async getAuditTrailReport(body: any) {
    const url = '/Audit';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async getWithdrawalReport(body: any) {
    const url = '/Withdrawal/GetAll';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async getGlobalWithdrawalExcelReport(body: any) {
    const url = '/Withdrawal/Excel/File';
    const res = await axios({
      url: `${url}`,
      method: 'POST',
      responseType: 'blob',
      data: body,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `WithdrawalsReport.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
    return res;
  }

  public static async getAggregationReport(body: any) {
    const url = '/Report/Aggregation';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async getShipmentReport(body: any) {
    const url = '/Report/Shipping';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async getExcelSeriesReport(body: any) {
    const url = '/Report/Series/Excel/File';
    const res = await axios({
      url: `${url}`,
      method: 'POST',
      responseType: 'blob',
      data: { receiptIds: body.receiptIds },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Report by series ${body.series}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
    return res;
  }

  public static async getExcelTableReport(body: any) {
    const url = '/Report/Shipping/Excel/File';
    const res = await axios({
      url: `${url}`,
      method: 'POST',
      responseType: 'blob',
      data: body,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Shipment Report by SSCC ${body?.SSCC}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
    return res;
  }

  public static async getGlobalShipReport(body: any) {
    const url = '/Report/Shipping/Excel/File/BySsccs';

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios({
        url: `${url}`,
        method: 'POST',
        responseType: 'blob',
        data: body,
      });

      if (response.status !== 204) {
        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `GatewayShipmentReport.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return response;
      } else {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async getTextReport(body: any) {
    const url = '/Report/Detailed/ByAny';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async getPhoto(params: any) {
    const url = `/Image/Show?fileName=${params}`;
    const response: AxiosResponse = await axios.get(url, { responseType: 'blob' });
    return response;
  }

  public static async getTreeReport(body: any) {
    const url = '/Report/Graph/Tree/ByAny';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async downloadReport(fileName: any) {
    const requestUrl = `/Blob/Download?fileName=${fileName}`;

    // eslint-disable-next-line no-useless-catch
    try {
      const res = await axios({
        url: `${requestUrl}`,
        method: 'GET',
        responseType: 'blob',
      });

      if (res.status !== 204) {
        const blobUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `${fileName}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async getSendersInfo(body: any) {
    const url = '/Report/Sender/GetInfo';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async updateSenderState(body: any) {
    const url = '/Report/Sender/UpdateState';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async getBackgroundProcesses(params: any) {
    const url = `/System/BackgroundProcesses?startDate=${params}`;
    const response: AxiosResponse = await axios.get(url);
    return response;
  }

  public static async getSendersLog(body: any) {
    const requestUrl = '/Report/Sender/Logs';
    const res = await axios({
      url: `${requestUrl}`,
      method: 'POST',
      responseType: 'blob',
      data: body,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Last Response.txt`);
      document.body.appendChild(link);
      link.click();
    });
    return res;
  }
}
